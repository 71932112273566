var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "table",
      { staticClass: "form-group table table-hover w-100" },
      [
        _c(
          "transition-group",
          { attrs: { name: "item", tag: "tbody" } },
          _vm._l(_vm.templates, function(template) {
            return _c("item", {
              key: template.id,
              attrs: {
                id: template.id,
                "name-col": template.nameCol,
                "value-col": template.valueCol,
                "name-comment-col": template.nameCommentCol,
                "value-comment-col": template.valueCommentCol
              }
            })
          }),
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("p", [_vm._v("Добавить новый пункт...")]),
    _vm._v(" "),
    _c("div", { staticClass: "input-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.colName,
            expression: "colName"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text", placeholder: "Заголовок" },
        domProps: { value: _vm.colName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.colName = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.colMemo,
            expression: "colMemo"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text", placeholder: "Комментарий" },
        domProps: { value: _vm.colMemo },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.colMemo = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-success",
            attrs: { type: "button" },
            on: { click: _vm.addItem }
          },
          [_vm._v("+")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }