var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c("div", { staticClass: "form-group p-0" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-5" }, [
            _c("input", {
              staticClass: "form-control border-0 shadow-none bg-transparent ",
              attrs: { type: "text", name: _vm.nameCol },
              domProps: { value: _vm.valueCol }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("input", {
              staticClass:
                "form-control border-0 shadow-none bg-transparent text-muted ",
              attrs: { type: "text", name: _vm.nameCommentCol },
              domProps: { value: _vm.valueCommentCol }
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }